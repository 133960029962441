import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { Link } from "gatsby";
import termsImg from "../assets//images/terms-of-service.jpg";

const TermsOfService = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Terms and Conditions"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Terms and Conditions"
      />
      <section className="terms-of-service-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="terms-of-service-content">
                <img src={termsImg} alt="tt" />
                <p>
                  <i>Terms and Conditions were last updated on Aril 2022.</i>
                </p>
                <blockquote className="blockquote">
                  <p>
                    Success Occupational Therapy provides innovative
                    occupational therapy solutions. We take great care in
                    selecting the offers featured on the Success Therapy
                    website. These Terms and Conditions set out how we do it
                    all, for you. This Website is owned and operated by Success
                    Occupational Therapy (ABN 42 865 202 416) (“Success
                    Occupational Therapy, Success Therapy, us, our or we”).{" "}
                  </p>
                </blockquote>
                <h3>1. Definitions</h3>
                <p>1.1 In these terms</p>
                <p>a) “you” means any person visiting or using the Website;</p>
                <p>
                  b) “Agreement” means the agreement between you and Success
                  Occupational Therapy for the provision of occupational therapy
                  services as governed by any registration form, welcome pack or
                  other documentation provided to you by Success Occupational
                  Therapy in conjunction with these Terms & Conditions;
                </p>
                <p>
                  c) “Terms” means any and all of the terms and conditions of
                  use of the website included in this Agreement or on the
                  website, including without limitation any privacy policy, or
                  in any other agreement which forms part of the Terms;
                </p>
                <p>
                  d) “Website” means the website www.successtherapy.com.au and
                  its related pages, including any mobile site or mobile or
                  other application that we may offer;
                </p>

                <h3>2. Use of Website</h3>
                <p>
                  2.1. Your access to, browsing, contributions to and use of
                  this Website are subject to the following Terms as well as all
                  applicable laws, rules and regulations. By accessing or using
                  this Website you accept and agree to the Terms, which are
                  legally binding.{" "}
                </p>
                <p>
                  2.2. If you do not agree to any of these Terms, you must not
                  visit, use or continue to use the Website. We will, from time
                  to time, revise and update the Terms and we will publish all
                  such updates on our Website. All updated Terms become
                  effective immediately upon being published on the Website.
                </p>

                <h3>3. Content</h3>
                <p>
                  3.1. All content and all intellectual property which includes
                  text, images, trade marks (registered or unregistered),
                  graphics, photographs, designs, icons, logos and service marks
                  (“Content”) and the selection, arrangement and "look and feel"
                  of all content contained on the Website, is the property of
                  Success Occupational Therapy or third parties who have granted
                  Success Therapy permission to use it on the Website. All
                  Content contained on the Website whether past, present or
                  future and is protected by national and international
                  copyright, design and trademark and other laws. Your right to
                  use the Content is limited only to those rights expressly set
                  out in the Terms.
                </p>

                <h3>4. Use and Registration</h3>
                <p>
                  4.1. Some of the material on this website may be for mature
                  audiences, and parents and guardians should take
                  responsibility for monitoring their children's use of the
                  Website.
                </p>
                <p>
                  4.2. Children under the age of 18 should not use this Website
                  without parental supervision. If we do find that someone under
                  the age of 18 is accessing the Website, Success Occupational
                  Therapy may restrict their access to the Website and may
                  cancel or restrict access to any services to be delivered to
                  the parent or guardian of that child. Any cancellation or
                  restriction of services to the parent as a result of the
                  unsupervised use of this Website by a child will result in the
                  parent forfeiting any payments made to Success Occupational
                  Therapy and no refund will be available.
                </p>
                <p>
                  4.3. Certain services will only be available after you have
                  registered your details and activated an account with us on
                  the Website. To register your details and open an account with
                  us, you will be required to submit your data and personal
                  information. You warrant and agree that all information
                  provided to us is accurate, true and up-to-date in all
                  respects and at all times. You also agree to update your
                  account with any changes to your personal information
                  previously submitted as soon as practicable after such
                  personal information changes. In particular, you warrant that
                  you will provide accurate information to Success Occupational
                  Therapy of any previous or contraindicating medical condition
                  before receiving any service.{" "}
                </p>
                <p>
                  4.4. Success Occupational Therapy reserves the right in its
                  sole discretion to refuse to register any account.
                </p>
                <p>
                  4.5. If you breach any of these Terms, we will be entitled to
                  lock or delete any and all accounts, and not accept any
                  further or future account requests, by the account holder and
                  their associates.
                </p>
                <p>
                  4.6. You must not use another person’s account without their
                  express prior permission nor permit anyone to use your
                  subscription or account.
                </p>

                <h3>Unauthorised Use</h3>
                <p>
                  4.7. You are solely responsible for all activity that occurs
                  using your account, and solely responsible to keep your access
                  to it and your passwords secure. Success Occupational Therapy
                  does not accept any responsibility for activity or purchases
                  made using your account by unauthorised access of your
                  account.
                </p>
                <p>
                  4.8. If you suspect or become aware of any unauthorised use of
                  your account or that your passwords are no longer secure, you
                  must immediately notify Success Occupational Therapy and
                  immediately take all reasonable steps to protect your account.
                </p>
                <p>
                  4.9. When you notify Success Occupational Therapy of your
                  account being accessed without your authority or your account
                  or passwords not being secure, we will immediately lock and
                  cancel your account to prevent any further misuse. We will
                  allow you to register a new account only after we are
                  satisfied, in our sole discretion, that you have taken all
                  reasonable steps to ensure that your account and passwords
                  will be kept secure in the future.
                </p>
                <p>
                  4.10. You agree to release and indemnify Success Occupational
                  Therapy from any loss or claim arising, whether directly or
                  indirectly, from any unauthorised use of your account.
                </p>

                <h3>Communications.</h3>
                <p>
                  4.11. You acknowledge that when you register and use the
                  Website, you will receive correspondence from Success
                  Occupational Therapy, including newsletters, relating to the
                  Website and the business of Success Therapy.
                </p>

                <h3>Limited license to use</h3>
                <p>
                  4.12. Success Occupational Therapy grants you a revocable
                  limited non-exclusive licence to access, browse, use or
                  contribute to the Website or the Content on your computer or
                  similar device and download and use downloadable Content for
                  personal, private or domestic purposes only or for the sole
                  purpose of making purchases from or interacting with Success
                  Occupational Therapy.
                </p>
                <p>
                  4.13. You warrant that you are not visiting or using the
                  Website or Content or purchasing any goods or services from
                  the Website or from Success Occupational Therapy for any
                  commercial purpose and will not access, browse, use or
                  contribute to the Website for a commercial purpose.
                </p>
                <p>
                  4.14. Except as expressly permitted in these Terms or on the
                  Website, or with Success Occupational Therapy's prior written
                  permission, you are expressly prohibited from modifying,
                  copying, downloading, distributing, transmitting, displaying,
                  publishing, selling, licensing, creating derivative works or
                  otherwise using Content available via this Website for
                  commercial or public purposes, including but not limited to
                  use as meta tags or linking the Website or Content on other
                  pages or sites on the world wide web.
                </p>

                <h3>Non-Interferance.</h3>
                <p>
                  4.15. You must not damage, interfere with or disrupt access to
                  the Website or the Content or engage in any other conduct that
                  will or may restrict or limit any other person from using or
                  accessing the Website or the Content. You agree not to, or
                  attempt to, circumvent, disable or otherwise interfere with
                  security-related features of the Website or features that
                  prevent or restrict use or copying of any Content or enforce
                  limitations on the use of the Website or the Content within
                  it.
                </p>
                <p>
                  4.16. Success Occupational Therapy will hold you liable for
                  any loss, damage or claims arising, directly or indirectly,
                  from your interference with the Website, Content, security or
                  access to the Website or Content or in any other way prevent
                  the usual operation of the Website and its availability to the
                  public.
                </p>

                <h3>5. Purchasing</h3>
                <p>
                  5.1. Success Occupational Therapy may offer services or
                  programs for purchase or subscription.
                </p>
                <p>
                  5.2. Our services or programs may be available for purchase
                  via our Website or directly from Success Occupational Therapy.
                  All services or programs are purchased subject to any order
                  requirements or instructions referred to on the Website or
                  otherwise notified to you.
                </p>
                <p>
                  5.3. When placing an Order you will be required to select the
                  services or program you wish to purchase and provide the
                  requisite details for the Order. For each Order you must
                  provide accurate and up-to-date details of the purchaser,
                  payment details and any other information required to
                  effectively process your Order.
                </p>
                <p>
                  5.4. By placing an Order, you warrant you are not a legal
                  minor and you are over the age of 18 years and you have full
                  and due capacity and authority to make the Order.
                </p>
                <p>
                  5.5. Once you have submitted an Order, you must make payment
                  for the Order in accordance with the payment methods specified
                  or referred to on the Website or otherwise notified to you. An
                  Order will only be deemed to be placed when you have submitted
                  the Order and the full payment required on placing the Order
                  has been received.
                </p>
                <p>
                  5.6. Success Occupational Therapy reserves the right to accept
                  or reject your Order for any reason including, the
                  unavailability of any services or programs, an error in the
                  price or description or any services or programs or any error
                  in your order, and in the event that we cancel your order, we
                  will provide a full refund of any payments received from you.
                </p>

                <h3>6. Price and Payments</h3>
                <p>
                  6.1. The price of a service or program is the price specified
                  or referred to on the Website or otherwise notified to you. We
                  may at our sole discretion from time to time update the price
                  of any service or program. All prices are processed in
                  Australian dollars unless otherwise notified on the Website.
                </p>
                <p>
                  6.2. Payment in accordance with the payment requirements for
                  your Order must be received by us prior to the provision of
                  the services or program that you have ordered.
                </p>
                <p>
                  6.3. If you receive in-person services from Success
                  Occupational Therapy which are not the subject of an Order,
                  you are required and agree to make full payment of the fees
                  for such services within 14 days of the services being
                  provided.
                </p>
                <p>
                  6.4. If services are to be provided online then full payment
                  for such services must be received by Success Occupational
                  Therapy before the services will be made available, unless
                  otherwise notified to you by Success Therapy.
                </p>
                <p>
                  6.5. Success Occupational Therapy accepts no liability for
                  unauthorised use of any of your payment, credit card or bank
                  account details, including due to fraud, forgery or other
                  similar acts. You agree to release and indemnify Success
                  Occupational Therapy against any loss, damage or claim
                  arising, directly or indirectly, from any unauthorised use of
                  your payment details.
                </p>

                <h3>7. Your contract with Us</h3>
                <p>
                  7.1. If any service or program has a delivery timeframe, it is
                  your responsibility to be available to receive the service or
                  program within the specified timeframe. If you fail to receive
                  the service or program within the specified timeframe the
                  service or program will expire and any unused portion of a
                  service or program cannot be redeemed for cash, credit or any
                  other value.
                </p>
                <p>
                  7.2. You acknowledge that you are solely responsible for
                  complying with all terms, conditions or instructions of any
                  service or program as described or referred to on the Website
                  or otherwise notified to you (“Instructions”). Success
                  Occupational Therapy is under no obligation to provide a
                  service or program to you if you do not comply with the
                  Instructions.
                </p>
                <p>
                  7.3. You release and indemnify Success Occupational Therapy
                  against any loss, damage or claims that may arise as a result,
                  directly or indirectly, of the conduct of yourself or any
                  third party in the provision or receipt of any services or
                  program or your compliance with the Instructions.
                </p>
                <p>
                  7.4. You acknowledge that you are solely responsible for
                  reading all instructions for the receipt of services or
                  programs as specified on the Website and you are solely
                  responsible for complying with such instructions. If you
                  believe the instructions to be unclear you must contact
                  Success Occupational Therapy directly prior to the expiration
                  of the specified timeframe for clarification of your
                  obligations.
                </p>

                <h3>8. Delivery of Services</h3>
                <p>
                  8.1. As soon as practicable after the placement of your Order
                  an email notification will be issued to confirm your Order.
                  The confirmation email will be sent to the email address
                  recorded in your account only and Success Occupational Therapy
                  is under no obligation to notify you of confirmation in any
                  other manner.
                </p>
                <p>
                  8.2. You are solely responsible to ensure the accuracy of the
                  email details recorded in your account and that you have
                  access to such email account. If you fail to receive your
                  confirmation email notification because you have recorded
                  incorrect email details or cease having access to the email
                  account, Success Occupational Therapy will not be liable for
                  any loss that may arise.
                </p>
                <p>
                  8.3. Once the required payment has been received in full,
                  Success Occupational Therapy will, as soon as practicable,
                  contact you to confirm details for delivery of the service or
                  program. If services are to be booked by you through the
                  Website, it is your responsibility to book in to receive such
                  services within the specified timeframe.
                </p>

                <h3>9. Personal Information</h3>
                <p>
                  9.1. Success Occupational Therapy may collect your personal
                  information for the purpose of delivering the services or
                  programs and will hold and use your personal information in
                  accordance with its privacy policy.
                </p>
                <p>
                  9.2. You agree that Success Occupational Therapy may disclose
                  your personal information to third party health professionals
                  for the purpose of delivering the services and may make
                  referrals to third party health professionals where
                  appropriate and where such referral has been previously
                  discussed with and agreed by you.
                </p>

                <h3>10. Third Pary Sites</h3>
                <p>
                  10.1. Success Occupational Therapy selects third party
                  partners and associates with care and with our customers’
                  needs in mind. We aim to provide information and services from
                  reputable third parties with quality products or services who
                  will be able to fully deliver that information or those
                  services to you.
                </p>
                <p>
                  10.2. The Website may contain links to third party websites or
                  content (Third Party Content). Success Occupational Therapy
                  does not monitor, review or update, and does not have any
                  control over, Third Party Content. Unless expressly stated
                  otherwise, Success Occupational Therapy does not endorse or
                  adopt Third Party Content and makes no representation,
                  warranty or guarantee as to accuracy, completeness, timeliness
                  or reliability of Third Party Content or the safety of any
                  third party website.{" "}
                </p>
                <p>
                  10.3. If you use the links contained on our Website to access
                  Third Party Content you do so entirely at your own risk and
                  liability. In addition, without limiting any other part of the
                  Terms, reference to any products, services, processes or other
                  information by name, trademark, manufacturer, supplier or
                  otherwise on or via the Website does not constitute or imply
                  any endorsement, sponsorship or recommendation by Success
                  Occupational Therapy.
                </p>

                <h3>11. No Warranties</h3>
                <p>
                  11.1. The Website and Content are provided "as is" without
                  warranty of any kind, express or implied. Except to the extent
                  required by the law, Success Occupational Therapy does not
                  make any representations or warranties as to the Website,
                  including but not limited to, warranties of merchantability
                  and fitness for a particular purpose, title, non-infringement,
                  security, informational content, system integration or
                  accuracy and the express warranty of quiet enjoyment or as to
                  the accuracy or suitability of information, descriptions,
                  guidelines and images on the Website including colour, design
                  and texture samples, descriptions of any materials or
                  products, services, offers, merchants or any other information
                  shown on the Website.{" "}
                </p>
                <p>
                  11.2. Success Occupational Therapy does not represent or
                  warrant that the Website or its server(s), applications or
                  functionalities are free of viruses or other harmful
                  components, and you bear the entire risk of losses or damages
                  that you may incur or suffer as a result of any use of or
                  visit to this Website. Success Occupational Therapy does not
                  guarantee continuous, uninterrupted, error-free or secure
                  access to the Website or its services, since the operation of
                  the Website may be interfered with by numerous factors outside
                  of Success Occupational Therapys' control.
                </p>

                <h3>12. Limitation of Liability</h3>
                <p>
                  12.1. Except to the extent required by the law or as is
                  expressly set out in the Terms, Success Occupational Therapy
                  specifically disclaims all or any liability arising from your
                  access to or use of the Website and Content, making any Order
                  or purchasing any services or program, and in no event will
                  Success Occupational Therapy, its subsidiaries, affiliates,
                  officers, directors, shareholders, employees or agents be
                  liable for any loss or damage of any kind, including indirect,
                  incidental, consequential, punitive, or special damages or any
                  loss of use, loss of profits or loss of data, whether in an
                  action in contract, tort, strict liability or otherwise
                  (including but not limited to negligence), arising out of or
                  in any way connected with those matters (even if Success
                  Occupational Therapy has been advised of the possibility of
                  such damages).{" "}
                </p>
                <p>
                  12.2. In any event, Success Occupational Therapy’s total
                  liability to you for damages, losses, and causes of action
                  (whether in contract or tort (including, but not limited to,
                  negligence) or otherwise) will not exceed the amount paid by
                  you, if any, for accessing the Website or purchasing any
                  services.
                </p>
                <p>
                  12.3. Success Occupational Therapys’ liability for breach of
                  any condition or warranty implied by legislation is, at
                  Success Occupational Therapy option, limited to supplying
                  services again or payment of the cost of having services
                  supplied again.
                </p>
                <p>
                  12.4. Without limiting the above, you acknowledge that Success
                  Occupational Therapy accepts no responsibility or any
                  liability for Orders made by you, and you acknowledge that
                  Instructions are to be strictly observed and followed at your
                  own risk. Success Therapy does not accept any responsibility
                  or liability for any information or errors provided by you in
                  the Order, or in booking or receiving any services, including
                  your failure to do all things necessary to ensure the order,
                  booking and receipt of services, are suitable and safe for
                  your circumstances.
                </p>
                <p>
                  12.5. You release and forever discharge Success Occupational
                  Therapy, its officers, employees and agents from all and any
                  loss, damage or liability (including indirect, special or
                  consequential) excluded under these Terms, including from any
                  third party claim, except to the extent that any legislation
                  applies and cannot be lawfully excluded.
                </p>

                <h3>13. Indemnifcation</h3>
                <p>
                  13.1. You indemnify and will keep indemnified, Success
                  Occupational Therapy and its subsidiaries, affiliates,
                  officers, directors, agents, shareholders, employees or agents
                  and will hold us harmless from any claim or demand, including
                  reasonable attorneys' fees, made by any third party due to or
                  arising out of your breach of the Terms, or your violation of
                  any law or the rights of any party. You also indemnify and
                  will keep indemnified Success Therapy from any and all damage
                  you may cause Success Occupational Therapy, its business, or
                  the Website due to your interaction with the Website including
                  without limitation your contributions, if any to the Website.
                </p>
                <p>
                  13.2. You indemnify and will keep indemnified and hold
                  harmless Success Occupational Therapy, its officers, employees
                  and agents against any loss or damage of any kind that is
                  either excluded under the Terms, including from any third
                  party claim, for any property or injury to or death of any
                  person, or caused as a result in any way by a breach by you of
                  the Terms, any negligent act or omission or wilful misconduct
                  of yours or any claim, suit or action brought against Success
                  Therapy as a result of your ordering, booking and receiving
                  any services or programs.
                </p>
                <p>
                  13.3. This Clause contains continuing separate obligations and
                  it survives termination.
                </p>

                <h3>14. Termination</h3>
                <p>
                  14.1. Success Occupational Therapy reserves the right, without
                  notice and in its sole discretion, to restrict or terminate
                  your ability to use the Website, and to limit, block or
                  prevent access to and use of this Website, including access to
                  and use of your account or orders, without any liability to
                  you. Failure by you to abide by the Terms revokes your
                  authorisation to use the Website and its services, including
                  any licence granted.
                </p>
                <p>
                  14.2. Success Occupational Therapy reserves its right to
                  refuse service, terminate an Order or change or remove the
                  Website or Content at its sole discretion and will not be
                  liable for any damages, loss or expenses of any kind,
                  including indirect or consequential loss or damage, suffered
                  or incurred by you in any way (including due to negligence) as
                  a result of refusal, termination, change or removal, or in
                  connection with your reliance on the Order in any way, other
                  than a refund that is expressly permitted under the Terms.
                </p>

                <h3>15. Intellectual Property</h3>
                <p>
                  15.1. You acknowledge that Success Occupational Therapy is the
                  owner or licensee of all intellectual property rights in the
                  Website and all works and designs therein and nothing in these
                  Terms grants or gives you any intellectual property rights in
                  the Website, works or designs, which to avoid doubt are
                  absolutely assigned to Success Therapy.
                </p>
                <p>
                  15.2. You acknowledge and agree that all information and
                  materials provided to you in the delivery of the services or
                  programs are the sole property of Success Occupational
                  Therapy. You are not permitted to use, distribute or in any
                  manner share any materials or information received by you from
                  Success Therapy or any other party in the delivery of services
                  or programs to you.
                </p>
                <p>
                  15.3. Upon contributing or providing content of any kind to
                  the Website, including via third party sites such as Facebook
                  or Twitter, you immediately grant Success Occupational Therapy
                  a licence to use it for the purpose for which it was given and
                  for our reasonable promotional or marketing purposes relating
                  to the Website and you acknowledge that this clause is
                  sufficient to give effect to the licence. You acknowledge that
                  any contribution you make to the Website or via other sites,
                  does not in any way give you a right, title or interest in the
                  Content or the Website and you warrant to Success Therapy you
                  have all necessary rights, including copyright, in the content
                  you are contributing and have not granted any rights
                  inconsistent with any rights granted under these Terms. You
                  warrant that any content contributed by you is not unlawful,
                  defamatory, false or misleading, in violation of laws
                  including privacy, harassment or discrimination, infringing
                  rights, abusive, offensive, obscene or inappropriate,
                  including by being vexatious, provocative, an endorsement or a
                  solicitation and there is currently no claim or dispute with
                  any party about any ownership or use of it.
                </p>
                <p>
                  15.4. All material published on the Website may, at Success
                  Occupational Therapys’ sole discretion, be edited, removed or
                  republished for any reason whatsoever.
                </p>

                <h3>16. Compititions</h3>
                <p>
                  16.1. The following terms along with any competition
                  information on the Website, including how to enter and prize
                  details apply to entry to any Success Occupational Therapy
                  competition (Competition) and by entering a Competition, you
                  agree to be bound by them. The promoter of any Competition is
                  Success Occupational Therapy unless stated otherwise
                  (Promoter).
                </p>
                <p>
                  16.2. The Competition details will specify any restrictions to
                  entry and, unless otherwise stated, the employees or
                  directors, and their immediate families of the Promoter and
                  its suppliers, agencies or other entities associated with the
                  Competition are ineligible to enter. If stated on the Website,
                  entrants must be over 18 years of age or comply with any other
                  requirements or limitations on entry. To enter you must comply
                  with entry requirements on the Website.
                </p>

                <h3>17. Updates and Revisions</h3>
                <p>
                  17.1. We may revise and update these Terms from time to time
                  and will publish the updated Terms on our Website. You
                  acknowledge and agree that you are bound by these Terms as
                  published from time to time including updated and revised
                  Terms. Success Occupational Therapy recommends that you
                  periodically visit this page to review and familiarise
                  yourself with all Terms and review the Website generally to
                  familiarize yourself with updates or revisions. We are not
                  obliged to notify you prior to any changes to the Website or
                  Terms, it is your responsibility to check the Website and
                  Terms from time to time.
                </p>

                <h3>18. Other Policies</h3>
                <p>
                  18.1. Success Occupational Therapy policies as published on
                  the Website form part of these Terms and governs the use of
                  the Website, including our Privacy Policy which governs our
                  practices for the collection, use and disclosure of your
                  personal information. By agreeing to the Terms you are also
                  agreeing to the Privacy Policy.
                </p>

                <h3>19. General</h3>
                <p>
                  19.1. Unless otherwise expressly stated, these Terms prevail
                  over any representation made on the Website or by Success
                  Occupation Therapy staff and personnel.
                </p>
                <p>
                  9.2. If either party fails to do anything it is entitled to
                  under these Terms that does not amount to a waiver of that
                  right. Any waiver or variation must be in writing.
                </p>
                <p>
                  19.3. If any clause or part of a clause is illegal or
                  unenforceable, it is to be treated as removed, but the rest of
                  this document is not affected. Any references to a party
                  include their agents, officers, employees or assigns. These
                  Terms are interpreted under and governed by laws and
                  jurisdiction of the courts of Western Australia.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <aside className="widget-area">
                <div className="widget widget_insight">
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li className="active">
                      <Link to="/terms-and-conditions">
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TermsOfService;
